<div class="full-height">
    <div class="errorPage">
        <div class="left-area">
            <img [src]="CDN_URL + 'new-landing/404-image.svg'" alt="404" />
        </div>
        <div class="right-area">
            <h1>{{ 'OFFLINE.OOPS' | translate }}!</h1>
            <h2>{{ '404_PAGE.TITLE_TEXT1' | translate }}</h2>
            <p>{{ '404_PAGE.TITLE_TEXT2' | translate }}</p>
            <div class="back-button cursor-pointer">
                <a class="back-home" [routerLink]="['/']"><em class="flaticon-right-arrow"></em></a>
                <span> {{ '404_PAGE.BACK' | translate }}</span>
            </div>
        </div>
    </div>
</div>
