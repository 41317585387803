import { Inject, inject, Injectable, PLATFORM_ID } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../authentication/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { STORAGE_KEY } from '../constants/enum.constants';
import {
    CoinInfoSocketResponse,
    MarketPairsSocketResponse,
    SwapPairsSocketResponse,
    WalletBalanceSocketResponse,
    WalletEventSocketResponse
} from '../models/socket-response.model';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { UserTradePairHistoryResponse } from 'src/app/trade-pro/models/trade-order-history.model';
import { WalletService } from 'src/app/wallet-v2/shared/service/wallet.service';

const channelToSubscription = new Map();

export const CONSTANT = {
    PATH: '/socket.io',
    CONNECT: 'connect',
    AUTHENTICATE: 'authenticate',
    AUTHENTICATED: 'authenticated',
    SUBSCRIBE: 'subscribe',
    UNSUBSCRIBE: 'unsubscribe',
    PRICE: 'price',
    DATA24H: '24hData',
    QUOTE: 'quote',
    CONVERT_HISTORY: 'convertHistory',
    TRADE_HISTORY: 'tradeHistory',
    WALLET_HISTORY: 'walletHistory', // deposit, withdraw
    WALLET_BALANCE: 'walletBalance', // swap, convert
    ALL_ORDERS: 'allOrders',
    DISCONNECT: 'disconnect',
    WEB_SOCKET: 'websocket',
    USER_ORDER_UPDATE: 'orderUpdate',
    POLLING: 'polling',
    PENDING_ORDERS: 'pendingOrders',
    DEPTH_DATA: 'depthData',
    CARDEX: 'Cardex',
    BUY_CRYPTO_HISTORY: 'buyCryptoHistory'
};

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    // Our socket connection
    private socket: Socket;
    room: string = '';
    selectedPair: string = '';
    private dataSource = new BehaviorSubject<any>([]);

    fiatPriceSource = new BehaviorSubject<any>(0);
    fiatPrice = this.fiatPriceSource.asObservable();
    tradeSelectedPair24hDataSource = new BehaviorSubject<any>(0);
    selectedPairLastTradePriceSource = new BehaviorSubject<any>({ last_price: 0, change: 0, is_up: null });
    walletBalance = new BehaviorSubject<any>({});
    tradeSelectedPair24hData = this.tradeSelectedPair24hDataSource.asObservable();
    liveTradeHistory = this.dataSource.asObservable();
    selectedPairLastTradePrice = this.selectedPairLastTradePriceSource.asObservable();
    buyCryptoOrderHistorySource = new BehaviorSubject<any>({});

    /**
     * These will be deleted in the next code iteration
     */
    allOrdersSource = new BehaviorSubject<any>({});
    allOrders = this.allOrdersSource.asObservable();
    convertOrders = this.allOrdersSource.asObservable();
    convertOrdersSource = new BehaviorSubject<any>({});

    private _coinInfoSubject = new Subject<any>();
    coinInfoObs = this._coinInfoSubject.asObservable();

    /**
     * Subject which emits a response whenever user executes/cancels a trade order
     * The trade order can be a Limit, Market, or a Stop Limit Order.
     */
    private userTradePairOrdersSubject = new Subject<UserTradePairHistoryResponse>();
    userTradePairOrdersObs = this.userTradePairOrdersSubject.asObservable();
    updateTradePairOrder(response: UserTradePairHistoryResponse) {
        this.userTradePairOrdersSubject.next(response);
    }

    // Wallet Balance Socket Data
    private walletBalanceSubject = new Subject<WalletBalanceSocketResponse>();
    walletBalanceObs = this.walletBalanceSubject.asObservable();

    private walletService = inject(WalletService);

    private walletHistorySubject = new Subject<WalletEventSocketResponse>();
    walletHistoryObs = this.walletHistorySubject.asObservable();

    // Swap Pairs Socket Data
    private swapPairsSubject = new Subject<SwapPairsSocketResponse[]>();
    swapPairsObs = this.swapPairsSubject.asObservable();

    private _socketStatusSubject = new BehaviorSubject<boolean>(false);
    socketStatusObs = this._socketStatusSubject.asObservable();
    private _spotMarketSubject = new Subject<MarketPairsSocketResponse>();
    socketSpotObs = this._spotMarketSubject.asObservable();
    constructor(
        private auth: AuthenticationService,
        private cookie: CookieService,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    connect(room: string) {
        if (isPlatformBrowser(this.platformId)) {
            const connectSocket = () => {
                if (this.socket && this.socket.connected) {
                    this.joinRoom(room);
                    return;
                }

                this.socket = io(environment.IXFI_WS_API, {
                    path: CONSTANT.PATH,
                    transports: [CONSTANT.WEB_SOCKET, CONSTANT.POLLING],
                    upgrade: true,
                    reconnection: true, // Enable reconnection
                    reconnectionAttempts: 5, // Number of attempts to reconnect
                    reconnectionDelay: 1000, // Initial delay between attempts (in milliseconds)
                    reconnectionDelayMax: 5000, // Maximum delay between attempts (in milliseconds)
                    randomizationFactor: 0.5 // Randomization factor to add jitter to reconnect delay
                });

                this.socket.on(CONSTANT.CONNECT, () => {
                    this.socket.emit(CONSTANT.AUTHENTICATE, {
                        token: this.auth.isLoggedIn() ? this.cookie.get(STORAGE_KEY.TOKEN) : ''
                    });
                    this.socket.on(CONSTANT.AUTHENTICATED, () => {
                        this.socket.emit(CONSTANT.SUBSCRIBE, room);
                        this.socket.on(room, data => console.info('data'));
                        this._socketStatusSubject.next(true);
                    });
                });

                this.socket.on(CONSTANT.PRICE, data => this._spotMarketSubject.next(data));
                this.socket.on(CONSTANT.PRICE, data => this.fiatPriceSource.next(data));
                this.socket.on(CONSTANT.DATA24H, data => this.tradeSelectedPair24hDataSource.next(data));
                this.socket.on(CONSTANT.QUOTE, (data: SwapPairsSocketResponse[]) => this.swapPairsSubject.next(data));
                /**
                 * Room: `quote`
                 * Emits Latest Swap Pairs Details
                 */ this.socket.on(CONSTANT.WALLET_BALANCE, (data: WalletBalanceSocketResponse) => {
                    this.walletBalanceSubject.next(data);
                    this.walletService.updateWallet({
                        coinId: data.coin_id._id,
                        balance: data.balance,
                        placedBalance: data.placed_balance,
                        stakedBalance: data.staked_balance
                    });
                });

                /**
                 * Event Name: `walletBalance`
                 * Emits Latest Balance Info for Coin in the User's Wallet
                 */
                this.socket.on(CONSTANT.WALLET_HISTORY, (data: WalletEventSocketResponse) =>
                    this.walletHistorySubject.next(data)
                );

                /**
                 * Event Name: `allOrders`
                 * Socket emits data to this event whenever the user executes or cancels a trade order
                 */
                this.socket.on(CONSTANT.ALL_ORDERS, (data: UserTradePairHistoryResponse) =>
                    this.userTradePairOrdersSubject.next(data)
                );

                /**
                 * Event Name: 'allOrders
                 */
                this.socket.on(CONSTANT.CONVERT_HISTORY, data => {
                    console.log('recent convert socket event');
                    this.convertOrdersSource.next(data);
                });

                /**
                 * Disconnect Event
                 */
                this.socket.on(CONSTANT.DISCONNECT, data => this.socket.disconnect());

                /**
                 * Event Name: 'buyCryptoHistory
                 */
                this.socket.on(CONSTANT.BUY_CRYPTO_HISTORY, data => this.buyCryptoOrderHistorySource.next(data));
                this.socket.on('disconnect', () => {
                    // Handle disconnect event
                    console.log('Socket disconnected. Attempting to reconnect...');
                    connectSocket(); // Reconnect the socket
                });

                this.socket.on('reconnect', () => {
                    // Handle successful reconnection
                    console.log('Socket reconnected');
                });

                this.socket.on('reconnect_attempt', attemptNumber => {
                    // Handle each reconnection attempt
                    console.log(`Attempting to reconnect (attempt ${attemptNumber})`);
                });
            };

            connectSocket();
        }
    }

    joinRoom(room = environment.SOCKET_ROOM_ID) {
        this.socket.emit(CONSTANT.SUBSCRIBE, room);
    }
    exitRoom(room: string) {
        if (this.socket && this.socket.connected) {
            this.socket.emit(CONSTANT.UNSUBSCRIBE, room);
        }
    }

    getTradeHistoryEvent(): Observable<any> {
        if (isPlatformServer(this.platformId)) return of();
        return new Observable(observer => {
            this.socket.on(CONSTANT.TRADE_HISTORY, data => {
                this.dataSource.next(data);
                observer.next(data);
            });
        });
    }

    getPendingOrdersEvent(): Observable<any> {
        if (isPlatformServer(this.platformId)) return of();
        return new Observable(observer => {
            this.socket.on(CONSTANT.PENDING_ORDERS, data => observer.next(data));
        });
    }
    getCoinInfoEvent(): Observable<CoinInfoSocketResponse> {
        if (isPlatformServer(this.platformId)) return of();
        return new Observable(observer => {
            this.socket.on(CONSTANT.PRICE, (data: CoinInfoSocketResponse) => {
                observer.next(data);
                this._coinInfoSubject.next(data); // Emit data to _coinInfoSubject
            });
        });
    }

    getMarketPairsEvent(): Observable<MarketPairsSocketResponse> {
        if (isPlatformServer(this.platformId)) return of();
        return new Observable(observer => {
            this.socket.on(CONSTANT.PRICE, data => {
                observer.next(data);
                this._spotMarketSubject.next(data); // Emit data to _spotMarketSubject
            });
        });
    }

    getDepthChartEvent(): Observable<any> {
        if (isPlatformServer(this.platformId)) return of();
        return new Observable(observer => {
            this.socket.on(CONSTANT.DEPTH_DATA, data => observer.next(data));
        });
    }

    onDisconnect() {
        if (this.socket && isPlatformBrowser(this.platformId)) this.socket.disconnect();
    }

    getTradeViewChartData(event, pair?) {
        if (isPlatformBrowser(this.platformId)) {
            this.selectedPair = pair;
            this.socket.on(event, data => {
                if (!data) {
                    return;
                }
                const exchange = CONSTANT.CARDEX;
                const [fromSymbol, toSymbol] = [this.selectedPair.split('-')[0], this.selectedPair.split('-')[1]];
                const channelString = `0~${exchange}~${fromSymbol}~${toSymbol}`;
                const subscriptionItem = channelToSubscription.get(channelString);
                if (subscriptionItem === undefined) {
                    return;
                }

                let bar = {
                    time: data[0].time,
                    open: data[0].open,
                    high: data[0].close,
                    low: data[0].low,
                    close: data[0].close,
                    isBarClosed: false,
                    isLastBar: true
                };
                subscriptionItem.lastDailyBar = bar;

                // send data to every subscriber of that symbol
                subscriptionItem.handlers.forEach(handler => handler.callback(bar));
            });
        }
    }

    subscribeOnStream(
        symbolInfo,
        resolution,
        onRealtimeCallback,
        subscribeUID,
        onResetCacheNeededCallback,
        lastDailyBar
    ) {
        const parsedSymbol: any = parseFullSymbol(symbolInfo.full_name);
        const channelString = `0~${parsedSymbol.exchange}~${parsedSymbol.fromSymbol}~${parsedSymbol.toSymbol}`;
        const handler = {
            id: subscribeUID,
            callback: onRealtimeCallback
        };
        let subscriptionItem = channelToSubscription.get(channelString);
        if (subscriptionItem) {
            // already subscribed to the channel, use the existing subscription
            subscriptionItem.handlers.push(handler);
            return;
        }
        subscriptionItem = {
            subscribeUID,
            resolution,
            lastDailyBar,
            handlers: [handler]
        };
        channelToSubscription.set(channelString, subscriptionItem);
    }

    unsubscribeFromStream(subscriberUID) {
        // find a subscription with id === subscriberUID
        for (const channelString of channelToSubscription.keys()) {
            const subscriptionItem = channelToSubscription.get(channelString);
            const handlerIndex = subscriptionItem.handlers.findIndex(handler => handler.id === subscriberUID);
            if (handlerIndex !== -1) {
                // remove from handlers
                subscriptionItem.handlers.splice(handlerIndex, 1);

                if (subscriptionItem.handlers.length === 0) {
                    // unsubscribe from the channel, if it was the last handler
                    channelToSubscription.delete(channelString);
                    break;
                }
            }
        }
    }
}

export function parseFullSymbol(fullSymbol) {
    const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
    if (!match) {
        return null;
    }
    return {
        exchange: match[1],
        fromSymbol: match[2],
        toSymbol: match[3]
    };
}
