import { UntypedFormGroup, Validators } from '@angular/forms';
import { VALIDATION_PATTERNS } from './constants/enum.patterns';

export function MustMatch(controlName: string, matchingControlName: string) {
    return (f: UntypedFormGroup) => {
        const control = f.controls[controlName];
        const matchingControl = f.controls[matchingControlName];
        // return if another validator has already found an error on the matchingControl
        if (matchingControl.errors && !matchingControl.errors.mustMatch) return;

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) matchingControl.setErrors({ mustMatch: true });
        else matchingControl.setErrors(null);
    };
}

export function DynamicFormValidation(f: UntypedFormGroup, m: any) {
    m.forEach(g => {
        let v = g === 'add_number' ? 'new_sms_otp' : `${g}_otp`;
        f.get(v)?.setValidators([Validators.required, Validators.pattern(VALIDATION_PATTERNS.OTP)]);
        f.get(v)?.updateValueAndValidity();
    });
}

export function toFixed(x) {
    if (Math.abs(x) < 1.0) {
        let e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + new Array(e).join('0') + x.toString().substring(2);
        }
    } else {
        let e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += new Array(e + 1).join('0');
        }
    }
    return x;
}

// clear validation
export function clearValidation(f: UntypedFormGroup, c) {
    c.forEach(e => {
        f.get(e)?.clearValidators();
        f.get(e)?.updateValueAndValidity();
    });
}
//add validation
export function addValidation(f: UntypedFormGroup, c) {
    c.forEach(e => {
        f.get(e.key)?.setValidators(e.validation);
        f.get(e.key)?.updateValueAndValidity();
    });
}

export function isVerified(r: Record<string, any>): boolean {
    let v = true;
    Object.keys(r).forEach(e => {
        v = typeof r[e] === 'boolean' ? v && r[e] : v && !!r[e];
        return r[e];
    });
    return v;
}

export const isWebview = () => {
    if (typeof window === undefined) {
        return false;
    }
    let navigator: any = window.navigator;
    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad/.test(userAgent);
    return ios ? !standalone && !safari : userAgent.includes('wv');
};

export function customSearch(searchTerm: string, item) {
    searchTerm = searchTerm.substring(searchTerm.indexOf('+') + 1, searchTerm.length).toLowerCase();
    return (
        item.phonecode === searchTerm ||
        item.name.toLowerCase() === searchTerm ||
        item.phonecode.includes(searchTerm) ||
        item.name.toLowerCase().includes(searchTerm) ||
        item.name.toLowerCase().indexOf(searchTerm) > -1 ||
        item.phonecode.indexOf(searchTerm) > -1
    );
}

export function unfreezeInput(el) {
    const value = el.target.value;
    // setting input value again makes it editable
    el.target.value = value;
}

export function getUniqueCoinPairs(arr, keyProps) {
    const kvArray = arr.map(entry => {
        const key = keyProps.map(k => entry[k]).join('|');
        return [key, entry];
    });
    const map = new Map(kvArray);
    return Array.from(map.values()).sort();
}

export function downloadPDF(htmlElement, from) {
    import('html2canvas').then(html2canvas => {
        html2canvas
            .default(htmlElement, {
                useCORS: true,
                allowTaint: true,
                scale: 2
            })
            .then(canvas => {
                const link = document.createElement('a');
                link.download = `${from}.jpg`;
                link.href = canvas.toDataURL('image/jpeg');
                link.click();
            });
    });
}
export function customSearchCountry(searchTerm: string, data) {
    if (searchTerm != '') {
        return data.filter(
            (e: any) =>
                [e?.sortname, e?.phonecode, e?.name].includes(searchTerm) ||
                e?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                e?.sortname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                e?.phonecode?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    } else return data;
}
export function customSearchCrypto(searchTerm: string, data) {
    if (searchTerm != '') {
        return data.filter(
            (e: any) =>
                [e?.coin_code, e?.coin_name].includes(searchTerm) ||
                e?.coin_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                e?.coin_code?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    } else return data;
}
export function customSearchCurrency(searchTerm: string, data) {
    if (searchTerm != '') {
        return data.filter(
            (e: any) =>
                [e?.code, e?.name].includes(searchTerm) ||
                e?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                e?.code?.toLowerCase().includes(searchTerm.toLowerCase())
        );
    } else return data;
}

export function returnNumberWithPrecision(value, precision, roundUpValue: boolean = false, fiatValue = true): any {
    if (!value) return value;
    if (value.toString().includes('e')) {
        value = toFixed(value);
    }
    let defaultFiatPrecision = 4;

    // Otherwise, calculate the multiplier for the specified precision
    let multiplier = Math.pow(10, precision);
    // Round the value to the specified precision
    let formattedValue = Math.round(value * multiplier) / multiplier;
    // If the formatted value is 0, use the DecimalPipe to format the value
    if (formattedValue === 0) {
        if (!roundUpValue) return returnLabel(value, defaultFiatPrecision, fiatValue);
        else return roundUp(value, defaultFiatPrecision);
    } else {
        // Otherwise, return the formatted value as a string
        if (!roundUpValue) return returnLabel(value, precision || defaultFiatPrecision, fiatValue);
        else return roundUp(value, precision);
    }
}

export function returnLabel(valueInString, precision, fiatValue: boolean = false) {
    if (valueInString) {
        let valueInNumber = parseFloat(valueInString);
        valueInString = valueInString.toString();
        const decimalIndex = valueInString.indexOf('.');
        if (decimalIndex !== -1) {
            if (fiatValue) {
                const indexOfNonZero = findIndexGreaterThanZeroAfterDecimal(valueInString);
                if ((valueInNumber < 1 && indexOfNonZero < 13) || (valueInNumber > 1 && indexOfNonZero < 7)) {
                    valueInString = valueInString.slice(0, indexOfNonZero + 2);
                    return valueInString;
                }
            }
            const numbersAfterDecimal = valueInString.slice(decimalIndex + 1, decimalIndex + precision + 1);
            if (precision === 0) {
                valueInString = valueInString.slice(0, decimalIndex);
            } else {
                valueInString = valueInString.slice(0, decimalIndex + 1) + numbersAfterDecimal;
            }
        }
    }
    return valueInString;
}

function roundUp(num, precision) {
    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
}

function findIndexGreaterThanZeroAfterDecimal(str: string) {
    let decimalIndex = str.indexOf('.');
    if (decimalIndex != -1 && decimalIndex < str.length - 1) {
        for (let i = decimalIndex; i <= str.length; i++) {
            let char = Number(str[i]);
            if (char > 0) {
                return i;
            }
        }
    }
    return -1;
}
