import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router, Route, UrlSegment, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StorageService } from '../../shared/services/storage.service';
import { STORAGE_KEY } from '../constants/enum.constants';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    constructor(
        private router: Router,
        private cookie: CookieService,
        private storage: StorageService,
        @Optional() @Inject(REQUEST) private httpRequest,
        @Inject(PLATFORM_ID) private platformId: object
    ) {}

    canLoad(
        _route: Route,
        segments: UrlSegment[]
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        /**
         * Returns true if the user is Logged In and Platform is server
         *
         */
        if (this.httpRequest?.cookies && isPlatformServer(this.platformId)) {
            const isLoggedIn = this.httpRequest?.cookies[STORAGE_KEY.IS_LOGGED_IN] === 'true';
            return isLoggedIn;
        }

        if (this.isLoggedIn()) {
            return true;
        } else {
            // navigate to login page as user is not authenticated
            let url = '';
            segments.forEach(s => (url = url + '/' + s.path));
            this.cookie.set(STORAGE_KEY.RETURN_URL, url, Date.now() + 60 * 1000 * 10, '/', environment.DOMAIN, true);
            this.router.navigate(['/auth/login']);
            return false;
        }
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        /**
         * Returns true if the user is Logged In and Platform is server
         *
         */
        if (this.httpRequest?.cookies && isPlatformServer(this.platformId)) {
            const isLoggedIn = this.httpRequest?.cookies[STORAGE_KEY.IS_LOGGED_IN] === 'true';
            return isLoggedIn;
        }

        // temporary fix for webview
        // todo: need to check why shell mobile is not able to set the cookies values
        if (state.url.includes('mobile')) {
            this.storage.setItem(STORAGE_KEY.IS_MOBILE, JSON.stringify(true));
            if (route.queryParams?.token) {
                this.storage.setItem(STORAGE_KEY.TOKEN, route.queryParams?.token);
                this.storage.setItem(STORAGE_KEY.IS_LOGGED_IN, JSON.stringify(true));
            }
        }

        if (this.isLoggedIn()) {
            return true;
        } else {
            // navigate to login page as user is not authenticated
            let url = state.url;
            this.cookie.set(STORAGE_KEY.RETURN_URL, url, Date.now() + 60 * 1000 * 10, '/', environment.DOMAIN, true);
            this.router.navigate(['/auth/login']);
            return false;
        }
    }

    public isLoggedIn(): boolean {
        let status = false;
        let token = this.cookie.get(STORAGE_KEY.TOKEN) || '';
        let expiry: any = this.cookie.get(STORAGE_KEY.EXPIRE_TIME) || '';
        let isMobile = this.storage.getItem(STORAGE_KEY.IS_MOBILE) || 'false';
        if (token && (JSON.parse(isMobile) || new Date(expiry * 1000) >= new Date())) {
            status = true;
        }
        return status;
    }
}
