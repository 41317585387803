import { FEE_STRUCTURE_SPOT_TRADING_TABLE_COLUMNS } from './fee-structure-spot-trading.columns';
import { FEE_STRUCTURE_DEPOSIT_TABLE_COLUMNS } from './fee-structure-deposit.columns';
import { Component, OnInit } from '@angular/core';
import { SpinnerService } from '../../../core';
import { environment } from '../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { FeeService } from './fee.service';
import { FEE_STRUCTURE_LIQUIDITY_TABLE_COLUMNS } from './fee-structure-liquidity.columns';
import { paginationRules } from '../../constants/enum.constants';
import { Subscription } from 'rxjs';
@Component({
    selector: 'app-fee-structure',
    templateUrl: './fee-structure.component.html',
    styleUrls: ['./fee-structure.component.scss']
})
export class FeeStructureComponent implements OnInit {
    CDN = environment.CDN_URL;
    feeList = [];
    feeListCopy = [];
    spotTradeFeeList: any;
    swapFeeList: any;
    swapVIPLevel: any[] = [];
    page = paginationRules.pageNumber;
    pageSize = 20;
    selectedPair;
    previousSelectedPair: any;
    isMobile: any;
    scrollRule: any = {
        position: [80, 80],
        tableId: 'fees-table'
    };
    depositColumnsConfig = FEE_STRUCTURE_DEPOSIT_TABLE_COLUMNS;
    spotTradingColumnsConfig = FEE_STRUCTURE_SPOT_TRADING_TABLE_COLUMNS;
    liquidityColumnsConfig = FEE_STRUCTURE_LIQUIDITY_TABLE_COLUMNS;
    subscriptions: Subscription[] = [];
    isLoading = false;
    constructor(private service: FeeService, private loader: SpinnerService, private activateRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.isMobile = this.activateRoute.snapshot.queryParams['is_mobile'] || null;
        this.loader.show();
        this.depositWithdrawFee();
        this.liquidSwapFee();
        this.spotTrading();
    }

    depositWithdrawFee() {
        this.isLoading = true;
        const depositSub = this.service
            .getDepositFee()
            .subscribe({
                next: feeDetails => {
                    this.feeListCopy = feeDetails['data'] || [];
                    this.feeList = this.feeListCopy;
                    this.loader.hide();
                },
                error: () => {
                    this.loader.hide();
                },
                complete: () => {
                    this.isLoading = false;
                }
            });
        this.subscriptions.push(depositSub);
    }

    onSearch(event) {
        let value = event.target.value;
        this.feeList = this.feeListCopy.filter((val: any) => {
            let reg = new RegExp(value, 'i');
            return reg.test(val.coin_name) || reg.test(val.coin_code);
        });
    }

    spotTrading() {
        const tradingSub = this.service
            .getTradingFee()
            .subscribe({
                next: feeList => {
                    this.spotTradeFeeList = feeList['data'] || [];
                    this.loader.hide();
                },
                error: () => {
                    this.loader.hide();
                }
            });
        this.subscriptions.push(tradingSub);
    }

    liquidSwapFee() {
        const swapSub = this.service
            .getSwapFee()
            .subscribe({
                next: feeDetails => {
                    this.swapVIPLevel = feeDetails['data']?.viplevel;
                    this.swapVIPLevel = this.swapVIPLevel.map((el, index) => {
                        return { level: el, index: index };
                    });
                    this.swapFeeList = feeDetails['data'];
                    if (this.swapFeeList && this.swapFeeList.swapdata && this.swapFeeList.swapdata.length) {
                        this.selectedPair = this.swapFeeList.swapdata[0];
                        this.previousSelectedPair = this.selectedPair;
                    }
                    this.loader.hide();
                },
                error: () => {
                    this.loader.hide();
                }
            });
        this.subscriptions.push(swapSub);
    }

    setBaseCoin(selectedPair) {
        this.selectedPair = selectedPair;
        if (this.selectedPair !== null) {
            this.previousSelectedPair = this.selectedPair;
        }
    }

    // close event of ng-select country dropdown
    initiateDefault() {
        if (this.selectedPair === null) {
            this.selectedPair = this.previousSelectedPair;
        }
    }

    clearSelection() {
        this.selectedPair = null;
    }

    onPageChange(event) {
        this.page = event;
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
