import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { STORAGE_KEY, STATIC_PAGE_SLUG, PLATFORMS } from '../../constants/enum.constants';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-cookie-popup',
    templateUrl: './cookie-popup.component.html',
    styleUrls: ['./cookie-popup.component.scss']
})
export class CookiePopupComponent implements OnInit {
    @ViewChild('toastElement') toastElement!: ElementRef<HTMLDivElement>;
    CDN = environment.CDN_URL;
    cookieConsent: boolean;
    slugList = STATIC_PAGE_SLUG;
    isWebView!: boolean;
    isMobileDevice!: boolean;
    showDownload!: boolean;
    cookiesImg: HTMLElement | null;

    constructor(private cookie: CookieService, @Inject(PLATFORM_ID) private platformId: object) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.isWebView = JSON.parse(localStorage.getItem(STORAGE_KEY.IS_MOBILE) || 'false');
            if (!this.isWebView) {
                this.isMobileDevice = this.detectMob();
                this.getCookie();
            }

            if (this.cookieConsent) {
                this.showAppDownload();
            }

            window.addEventListener('scroll', () => {
                const theta = (window.scrollY / 100) % Math.PI;
                const divData = this.cookiesImg as HTMLElement;
                if (divData) divData.style.transform = 'rotate(' + theta + 'rad)';
            });
        }
    }
    ngAfterViewInit() {
        this.cookiesImg = document.getElementById('cookies_img');
    }
    getCookie() {
        this.cookieConsent = JSON.parse(this.cookie.get('cookieConsent') || 'false');
    }

    showAppDownload() {
        this.showDownload = JSON.parse(this.cookie.get(STORAGE_KEY.DOWNLOAD_APP) || 'true');
    }

    setCookie() {
        this.cookieConsent = true;
        this.cookie.set(STORAGE_KEY.COOKIE_CONSENT, JSON.stringify(true), undefined, '/', environment.DOMAIN);
        this.showAppDownload();
    }

    detectMob() {
        return [/Android/i, /webOS/i, /BlackBerry/i, /Windows Phone/i].some(toMatchItem => {
            return navigator.userAgent.match(toMatchItem);
        });
    }

    onClose() {
        this.toastElement.nativeElement.classList.remove('open-animation');
        this.toastElement.nativeElement.classList.add('close-animation');
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.showDownload = false;
                this.cookie.set(STORAGE_KEY.DOWNLOAD_APP, JSON.stringify(false), undefined, '/', environment.DOMAIN);
            }, 1000);
        }
    }
}
