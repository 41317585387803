import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    ConfirmationModalComponent,
    POP_UP_TYPE
} from 'src/app/standalone/components/confirmation-modal/confirmation-modal.component';
import { environment } from 'src/environments/environment';
import { TrackingEvents } from '../../constants/enum.constants';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../authentication';
import { CaptchaV4Service, PageTitleService } from '../../services';
import { GeetestCaptcha } from 'src/app/shared/models/geetest-captcha.model';
import { Subscription } from 'rxjs';

declare let fbq: Function;
declare let twq: Function;

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html'
})
export class ShellComponent implements OnInit, OnDestroy {
    routerData: any;
    isLoggedIn!: boolean;
    isBrowser!: boolean;
    subscriptions: Subscription[] = [];

    private toastService = inject(ToastMessageService);
    private modalService = inject(NgbModal);
    private captchaService = inject(CaptchaV4Service);
    private platformId = inject(PLATFORM_ID);
    private authService = inject(AuthenticationService);
    private http = inject(HttpClient);
    private metaService = inject(PageTitleService);
    private activatedRoute = inject(ActivatedRoute);

    constructor(private route: ActivatedRoute) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    ngOnInit(): void {
        // Commented because it is creating issue with buy crypto Meta
        // this.metaService.updateDynamicMeta({
        //     'og_image': environment.CDN_URL + 'SEO/meta-ixfi.png',
        //     'og_description':
        //         'More than crypto. Experience the gen 3.0 crypto exchange that allows you to buy, sell and trade Bitcoin, Ethereum and altcoins. Earn free crypto and enjoy a unique gamified experience for your everyday crypto activities'
        // });
        if (this.isBrowser) {
            const loginSub = this.authService.loginStatus.subscribe({
                next: response => (this.isLoggedIn = response)
            });
            this.subscriptions.push(loginSub);

            this.captchaService.initGeetest(environment.GEETEST_V4_APP_ID.OTHER);
        }
        this.redirectToFarmingPage()
    }

    onActivate(_event) {
        if (this.isBrowser) {
            const timeObj = setTimeout(() => {
                window.scrollBy(0, -window.pageYOffset);
                window.scrollTo(0, 0);
                clearTimeout(timeObj);
            });

            // get the route data to hide footer
            const sub:any = this.activatedRoute.firstChild?.data.subscribe(res => {
                this.routerData = res;
            });
            this.subscriptions.push(sub);
        }
    }

    onSubscribe(email: string) {
        this.captchaService.showBox(environment.GEETEST_V4_APP_ID.OTHER).then((res: GeetestCaptcha | null) => {
            if (res) this.newsLetter(res, email);
        });
    }
    private newsLetter(result: GeetestCaptcha, email: string) {
        const payload = {
            captcha_details: { ...result },
            email: email,
            flag: 1
        };
        const newsSub = this.http
            .post<{ message: string; status: number; data: { is_subscribed: boolean } }>(
                `${environment.IXFI_API}auth/subscribe-newsletter`,
                payload
            )
            .subscribe({
                next: response => {
                    if (response.status === 200) {
                        if (!response.data.is_subscribed && environment.production) fbq('track', 'Lead');
                        if (environment.production) twq('event', TrackingEvents.SUBSCRIBE_ID);
                        this.toastService.showSuccessToast({
                            toastMessage: response.message,
                            toastTitle: 'SUCCESS'
                        });
                    } else {
                        this.toastService.showErrorToast({
                            toastMessage: response.message,
                            toastTitle: 'ERROR'
                        });
                    }
                }
            });
        this.subscriptions.push(newsSub);
    }
    onLogout() {
        this.modalService.dismissAll();
        const modalRef = this.modalService.open(ConfirmationModalComponent, {
            size: 'sm',
            centered: true,
            backdrop: true
        });
        modalRef.componentInstance.data = { modalRef, data: {}, type: POP_UP_TYPE.LOG_OUT };
    }

    redirectToFarmingPage(){
        const routeSub = this.route.queryParams.subscribe(params => {
            const farming = params?.['is_farming'];
            if (farming === 'true') {
              window.location.href = 'https://farming.ixfi.com/';
            }
          });
        this.subscriptions.push(routeSub);  
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
