<div class="d-flex align-item-center justify-content-center h-100vh">
    <div class="maintenance-page">
        <div class="p-3 my-4 d-inline-block w-100 justify-content-center text-center">
            <a href="/landing"><img [src]="CDN_URL + 'new-header/logo.svg'" width="200" alt="ixfi Logo" /></a>
            <h1 class="pt-3">{{ 'SERVER-MANAGEMENT.WILL_BACK' | translate }}</h1>
            <div class="d-flex affiliate-counter">
                <div class="affiliate-hours">
                    <h3 id="countdownHours">{{ duration?.hours() }}</h3>
                    <span>{{ 'SERVER-MANAGEMENT.HOURS' | translate }}</span>
                </div>
                <div class="affiliate-minutes">
                    <h3 id="countdownMinutes">{{ duration?.minutes() }}</h3>
                    <span>{{ 'SERVER-MANAGEMENT.MINUTES' | translate }}</span>
                </div>
                <div class="affiliate-seconds">
                    <h3 id="countdownSeconds">{{ duration?.seconds() }}</h3>
                    <span>{{ 'SERVER-MANAGEMENT.SECONDS' | translate }}</span>
                </div>
            </div>
            <p>{{ 'SERVER-MANAGEMENT.UNDER_MAINTENANCE' | translate }}</p>
        </div>
    </div>
</div>
