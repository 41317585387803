import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, PLATFORM_ID, inject } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthenticationService, UserService } from './core';
import { STORAGE_KEY, TrackingEvents } from './core/constants/enum.constants';
import { Observable, Subscription, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationCancel, NavigationEnd, NavigationError, ResolveEnd, Router } from '@angular/router';
declare let fbq: Function;
declare let twq: Function;
declare let sbjs: any;

import { IxfiLanguageService, IxfiThemeService, Language } from 'header';

import { isPlatformBrowser } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from './shared/services/storage.service';
import { TradeService } from './trade/service/trade.service';
import { WalletService } from './wallet-v2/shared/service/wallet.service';
import { DynamicScriptService } from './shared/services/dynamic-script.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    depositData: any;
    tradeData: any;
    online$: Observable<boolean>;
    isOnline = true;
    isLoading = true;
    selectedLanguage!: Language;
    isBrowser!: boolean;
    subscriptions: Subscription[] = [];

    private translateService = inject(TranslateService);
    private modalService = inject(NgbModal);
    private languageService = inject(IxfiLanguageService);
    private walletService = inject(WalletService);
    private authService = inject(AuthenticationService);
    private themeService = inject(IxfiThemeService);
    private storageService = inject(StorageService);
    private platformId = inject(PLATFORM_ID);
    private userService = inject(UserService);
    private tradeService = inject(TradeService);
    private elementRef = inject(ElementRef);
    private scriptService = inject(DynamicScriptService);

    constructor(private router: Router, @Optional() @Inject(REQUEST) private httpRequest) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        if (!this.isBrowser) {
            if (this.httpRequest && this.httpRequest?.cookies) {
                // SET THEME for SERVER
                const isDarkMode = this.httpRequest?.cookies?.isDarkMode === 'true' || false;
                this.themeService.toggleTheme(isDarkMode);

                // SET LANGUAGE for SERVER
                if (this.httpRequest?.cookies?.selectedLanguage) {
                    const lang = JSON.parse(this.httpRequest?.cookies?.selectedLanguage) || null;
                    if (lang) this.languageService.updateLanguage(lang);
                }
            }
        }
        this.translateService.setDefaultLang('en');
        // loader subscription method
        this.loaderSubscription();
        // Toggle class on login & logout
        const isLoggedIn = JSON.parse(this.storageService.getItem(STORAGE_KEY.IS_LOGGED_IN) || 'false');
        if (isLoggedIn) {
            this.authService.startRefreshTokenTimer();
            document.body.classList.add('user-logged-in');
        } else document.body.classList.remove('user-logged-in');

        this.trackDepositAndTrade();

        this.checkForNewRelease();

        this.online$ = merge(
            of(navigator.onLine),
            fromEvent(window, 'online').pipe(map(() => true)),
            fromEvent(window, 'offline').pipe(map(() => false))
        );

        const onlineSub = this.online$.subscribe(res => {
            if (res && !this.isOnline) {
                this.isOnline = true;
                window.location.reload();
            } else {
                this.isOnline = false;
                this.modalService.dismissAll();
            }
        });
        this.subscriptions.push(onlineSub);
        // @ts-ignore: private option not yet exposed for public use
        router.canceledNavigationResolution = 'computed';
    }
    ngOnInit(): void {
        // Auth Status Subscription
        const authSub = this.authService.loginStatus.subscribe(status => {
            // User LoggedIn
            if (status) {
                document.body.classList.add('user-logged-in');
                // Fetch User Wallet
                if (this.router.url !== '/wallet') {
                    const walletSub = this.walletService.fetchUserWallet().subscribe();
                    this.subscriptions.push(walletSub);
                }
            }
            // User logged out
            else document.body.classList.remove('user-logged-in');
        });
        this.subscriptions.push(authSub);
        this.hideAngularVersion();
        const isoLanguageCode = this.languageService.selectedLanguage.language_code.toLowerCase();
        this.translateService.use(isoLanguageCode);

        if (this.isBrowser) {
            this.loadDynamicScript();
            const binanceAPISub = this.tradeService.checkApiFails().subscribe(
                (res: any) => {
                    if (res.status) this.tradeService.isBinanceApiFails = true;
                },
                _error => {
                    this.tradeService.isBinanceApiFails = true;
                }
            );
            this.subscriptions.push(binanceAPISub);
        }
    }

    loadDynamicScript() {
        this.scriptService.loadScript(environment.GT4_JS);

        //Fingerprint JS
        // this.scriptService.loadFingerprintScript();
    }

    hideAngularVersion() {
        this.elementRef?.nativeElement?.removeAttribute('ng-version');
    }

    loaderSubscription() {
        const routerSub = this.router.events.subscribe(event => {
            if (event instanceof ResolveEnd) this.isLoading = true;

            if (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError
            ) {
                this.isLoading = false;
            }
        });
        this.subscriptions.push(routerSub);
    }

    private trackDepositAndTrade() {
        if (this.authService.isLoggedIn() && environment.production) {
            const trackSub = this.userService.trackDepositWithdraw().subscribe((res: any) => {
                if (res.status === 200) {
                    this.depositData = res.data?.deposits;
                    this.tradeData = res.data?.trades;
                    if (!this.depositData.logs?.is_ack) {
                        fbq('track', 'Purchase', {
                            value: this.depositData.logs?.fiat_amount,
                            currency: this.depositData.logs?.currency
                        });
                        twq('event', 'Purchase', {
                            value: this.depositData.logs?.fiat_amount,
                            currency: this.depositData.logs?.currency
                        });
                        this.storageService.setItem(
                            STORAGE_KEY.IS_DEPOSIT,
                            JSON.stringify(true),
                            undefined,
                            '/',
                            environment.DOMAIN,
                            true
                        );
                    }
                    if (!this.tradeData.logs?.is_ack) {
                        fbq('track', 'AddToCart');
                        twq('event', TrackingEvents.TRADE_ID);
                        this.storageService.setItem(
                            STORAGE_KEY.IS_TRADE,
                            JSON.stringify(true),
                            undefined,
                            '/',
                            environment.DOMAIN,
                            true
                        );
                    }
                }
            });
            this.subscriptions.push(trackSub);
        }
    }

    private checkForNewRelease() {
        if (isPlatformBrowser(this.platformId)) {
            const releaseSub = this.authService.getRelease({ release_platform: 'web' }).subscribe(release => {
                if (release.data?.current_release?.RELEASE_VERSION !== environment.RELEASE_VERSION) {
                    // Clear caches
                    if (caches?.keys?.length > 0) caches.keys().then((e: any) => e.forEach(k => caches.delete(k)));
                }
            });
            this.subscriptions.push(releaseSub);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
